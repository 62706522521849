import Grid from '@mui/material/Grid'
import React, { memo } from 'react'
// import dynamic from 'next/dynamic'
import AppHeaderAccountButton from './AppHeaderAccountButton'
import AppHeaderCartButton from './AppHeaderCartButton'

// const AppHeaderAccountButton = dynamic(
//   () => import('./AppHeaderAccountButton'),
//   { ssr: false }
// )
// const AppHeaderCartButton = dynamic(() => import('./AppHeaderCartButton'), {
//   ssr: false,
// })

const NavToolbar = () => {
  return (
    <Grid
      className="nav-toolbar"
      container
      columnSpacing={{ xs: 0, lg: 1, xl: 2 }}
      alignItems="center"
      justifyContent="flex-end"
      sx={{ height: '100%' }}
    >
      <Grid item>
        <AppHeaderCartButton />
      </Grid>
      <Grid
        className={'dreo-app-hide'}
        item
        sx={{ height: '100%', width: 'auto' }}
      >
        <AppHeaderAccountButton />
      </Grid>
    </Grid>
  )
}

export default memo(NavToolbar)

// const LightTooltip = styled(
//   React.forwardRef<HTMLDivElement, TooltipProps>(function MyTooltip(
//     { className, ...props }: TooltipProps,
//     ref
//   ) {
//     return (
//       <Tooltip {...props} ref={ref} arrow classes={{ popper: className }} />
//     )
//   })
// )(({ theme }) => ({
//   [`& .${tooltipClasses.arrow}`]: {
//     color: theme.palette.common.white,
//   },
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: theme.palette.common.white,
//     color: 'rgba(0, 0, 0, 0.87)',
//     boxShadow: theme.shadows[1],
//     fontSize: 11,
//   },
// }))
