/*
 * @Author: Walker Qin
 * @Description:
 * @Date: 2022-09-14 14:26:35
 * @LastEditTime: 2023-05-25 17:55:58
 */
import { isDreoApp } from '@/utils'
import { SHOPIFY_ACCESS_TOKEN_KEY, USER_TOKEN_KEY } from 'constants/config'
import Router from 'next/router'
import { useCallback, useEffect, useMemo } from 'react'
import { useCookie } from 'react-use'
import { sentFindPasswordEmail } from 'src/api/account'
import { useAuth } from 'src/contexts/auth-context'
import { HttpData } from 'src/utils/helpers/http'
import { Key, useSWRConfig } from 'swr'
import { ForgotPasswordForm } from 'types/account'

/**
 * 发送修改密码邮件
 * @param queryKey
 * @returns
 */
export const useSentFindPasswordEmail = (
  queryKey: Key = '/api/sentFindPasswordEmail'
) => {
  const { mutate } = useSWRConfig()
  return useCallback(
    (params: ForgotPasswordForm): Promise<HttpData> =>
      mutate(queryKey, sentFindPasswordEmail(params)),
    [mutate, queryKey]
  )
}

/**
 * 获取用户token
 * @returns userToken
 */
export const useUserToken = () => {
  const [userToken, setUserToken] = useCookie(USER_TOKEN_KEY)
  return [useMemo(() => userToken ?? '', [userToken]), setUserToken] as const
}

/**
 * 获取shopify用户access token
 * @returns shopifyAccessToken
 */
export const useShopifyAccessToken = () => {
  const [shopifyAccessToken] = useCookie(SHOPIFY_ACCESS_TOKEN_KEY)
  return useMemo(() => shopifyAccessToken ?? '', [shopifyAccessToken])
}

/**
 * 修改用户信息
 * @param queryKey
 * @returns
 */
export const useUpdateUserInfo = (
  queryKey: Key = '/api/sentFindPasswordEmail'
) => {
  const { mutate } = useSWRConfig()
  return useCallback(
    (params: ForgotPasswordForm): Promise<HttpData> =>
      mutate(queryKey, sentFindPasswordEmail(params)),
    [mutate, queryKey]
  )
}

export const useUser = ({
  redirectTo,
  redirectIfFound = false,
  replace = false,
}: {
  redirectTo?: string
  redirectIfFound?: boolean
  replace?: boolean
} = {}) => {
  const { user, loading, token } = useAuth()
  // const Router = useRouter()

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    if (!redirectTo) return
    if (
      !isDreoApp() &&
      // If redirectTo is set, redirect if the user was not found.
      ((redirectTo && !redirectIfFound && !token) ||
        // If redirectIfFound is also set, redirect if the user was found
        (redirectIfFound && user))
    ) {
      replace ? Router.replace(redirectTo) : Router.push(redirectTo)
    }
  }, [user, token, redirectIfFound, redirectTo, loading, replace])

  return {
    user,
    loading,
    token,
  } as const
}
