import appApi from '@/utils/appApi'
import AccountIcon from '@icons/Account'
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { SxProps } from '@mui/system'
import { useRouter } from 'next/router'
import { useSnackbar } from 'notistack'
import React, { useCallback, useRef } from 'react'
import MyLink from 'src/components/Link'
import { useAuth } from 'src/contexts/auth-context'
import useCurrentMediaQuery from 'src/hooks/useCurrentMediaQuery'

const myLinkStyles: SxProps = {
  lineHeight: '38px',
  color: '#000000',
  fontSize: 14,
  fontWeight: 500,
  display: 'inline-block',
  padding: '0 16px',
  width: '100%',
}

export default function AccountButton() {
  const router = useRouter()
  const { user, logout } = useAuth()
  const { enqueueSnackbar } = useSnackbar()

  const logoutForm = useRef<HTMLFormElement>(null)

  const currentMediaQuery = useCurrentMediaQuery()

  const handleLogout = async () => {
    // 跳转首页
    // router.push('/')
    // 才用post表单提交的方式请求登出接口
    // if (logoutForm.current) logoutForm.current.submit()
    // fetch('https://dreo-plus-test.myshopify.com/account/logout').then(() => {
    //   router.push('/')
    // })
    // window.location.href = '/account/logout'
    // 请求登出页面

    const res = await logout()
    if (res.code !== 0) {
      enqueueSnackbar(res.msg || '', {
        variant: 'error',
      })
      return
    }
    enqueueSnackbar('success', {
      variant: 'success',
    })
    // router.push('/')
    // fetch(`https://${process.env.NEXT_PUBLIC_SHOPIFY_DOMAIN}/account/logout`)
    window.location.href = `https://${
      process.env.NEXT_PUBLIC_SHOPIFY_DOMAIN
    }/pages/logout?return_to=${encodeURIComponent(window.location.origin)}`
  }

  const [isOpen, setIsOpen] = React.useState(false)
  const [isAppEmbedded, setIsAppEmbedded] = React.useState(false)
  const anchorRef = React.useRef<HTMLButtonElement>(null)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleOpen = useCallback(() => {
    if (['lg', 'xl'].includes(currentMediaQuery)) setIsOpen(true)
  }, [currentMediaQuery])
  const handleClose = useCallback(() => {
    if (['lg', 'xl'].includes(currentMediaQuery)) {
      return setIsOpen(false)
    }
    setAnchorEl(null)
  }, [currentMediaQuery])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleAccountBtnClick = useCallback(() => {
    // window.location.href =
    //   'http://passport.dreo.com?redirect_uri=http://www.dreo.com/account/home'
    if (user) return router.push(`/account/home`)
    if (appApi.dreo) return appApi.login()
    router.push(`/account?redirectTo=${encodeURIComponent('/account/home')}`)
  }, [router, user])

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus()
    }

    prevOpen.current = open
  }, [open])

  React.useEffect(() => {
    handleClose()
  }, [currentMediaQuery, handleClose])

  React.useEffect(() => {
    if (appApi.dreo) setIsAppEmbedded(true)
  }, [])

  return (
    <>
      {!user ? (
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <IconButton
            onClick={handleAccountBtnClick}
            disableRipple
            aria-label="Account Button"
          >
            <AccountIcon
              sx={{
                color: '#000000',
                fontSize: {
                  xs: '20.572px',
                  md: 24,
                },
              }}
            />
          </IconButton>
        </Box>
      ) : !isAppEmbedded ? (
        <Box
          sx={{
            height: '100%',
            position: 'relative',
            zIndex: 999,
          }}
        >
          <Box
            sx={{
              height: '100%',
              display: {
                lg: 'flex',
                xs: 'none',
              },
              alignItems: 'center',
              '& .MuiMenuItem-root': {
                minHeight: 'auto',
              },
            }}
          >
            <IconButton
              disableRipple
              aria-label="Account Button"
              onMouseEnter={handleOpen}
              onMouseLeave={handleClose}
            >
              <AccountIcon
                sx={{
                  color: '#000000',
                  fontSize: {
                    xs: '20.572px',
                    md: 24,
                  },
                }}
              />
            </IconButton>
          </Box>

          {/* logout form */}
          <form
            ref={logoutForm}
            action="https://dreo-plus-test.myshopify.com/account/logout"
            method="post"
          >
            <input type="hidden" name="return_to" value="/collections/all" />
          </form>

          <Box
            className="menu-wrapper"
            onMouseEnter={handleOpen}
            onMouseLeave={handleClose}
            sx={{
              display: {
                lg: 'block',
                xs: 'none',
              },
              position: 'absolute',
              top: {
                xs: '42px',
                xl: '66px',
              },
              pt: 0,
              left: '50%',
              transform: 'translateX(-50%)',
              transition: 'all .3s ease',
              overflow: 'hidden',
              height: 0,
              mt: '-13px',
              ...(isOpen && {
                height: 250,
              }),
            }}
          >
            <Box
              className="account-menu-container"
              sx={{
                boxShadow: '0px 3px 20px 1px rgba(0, 0, 0, 0.16)',
                borderRadius: '10px 10px 10px 10px',
                bgcolor: '#fff',
                py: 1,
                m: '20px',
              }}
              component="ul"
              role="menu"
            >
              {user && (
                <Box
                  sx={{
                    position: 'relative',
                    mb: 1,
                    '&:after': {
                      content: '""',
                      position: 'absolute',
                      bottom: 0,
                      right: 0,
                      left: 0,
                      borderBottom: '1px solid #707070',
                      opacity: 0.4,
                    },
                  }}
                >
                  <MenuItem sx={{ p: 0 }}>
                    <Typography
                      mb={0.5}
                      sx={{
                        ...myLinkStyles,
                        fontSize: 16,
                        fontWeight: 'bold',
                      }}
                    >
                      {user?.nickname}
                    </Typography>
                  </MenuItem>
                </Box>
              )}
              <MenuItem sx={{ p: 0 }}>
                <MyLink
                  href={`/account/home`}
                  underline="none"
                  sx={myLinkStyles}
                >
                  My Account
                </MyLink>
              </MenuItem>
              <MenuItem onClick={handleLogout} sx={{ p: 0 }}>
                <Typography sx={myLinkStyles}>Log out</Typography>
              </MenuItem>
            </Box>
          </Box>

          <Box
            sx={{
              height: '100%',
              display: { xs: 'flex', lg: 'none' },
              alignItems: 'center',
            }}
          >
            <IconButton
              disableRipple
              aria-label="Account Button"
              onClick={handleClick}
            >
              <AccountIcon
                sx={{
                  color: '#000000',
                  fontSize: {
                    xs: '20.572px',
                    md: 24,
                  },
                }}
              />
            </IconButton>
          </Box>

          <Menu
            id="account-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'account-button',
            }}
            sx={{
              '& .MuiPaper-root': {
                boxShadow: '0px 3px 20px 1px rgb(0 0 0 / 16%)',
                borderRadius: '10px',
              },
            }}
          >
            {user && (
              <Box
                sx={{
                  position: 'relative',
                  mb: 1,
                  '&:after': {
                    content: '""',
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    left: 0,
                    borderBottom: '1px solid #707070',
                    opacity: 0.4,
                  },
                }}
              >
                <MenuItem sx={{ p: 0 }}>
                  <Typography
                    mb={0.5}
                    sx={{
                      ...myLinkStyles,
                      fontSize: 16,
                      fontWeight: 'bold',
                    }}
                  >
                    {user?.nickname}
                  </Typography>
                </MenuItem>
              </Box>
            )}
            <MenuItem sx={{ p: 0 }}>
              <MyLink href={`/account/home`} underline="none" sx={myLinkStyles}>
                My Account
              </MyLink>
            </MenuItem>
            <MenuItem onClick={handleLogout} sx={{ p: 0 }}>
              <Typography sx={myLinkStyles}>Log out</Typography>
            </MenuItem>
          </Menu>
        </Box>
      ) : (
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <IconButton
            onClick={handleAccountBtnClick}
            disableRipple
            aria-label="Account Button"
          >
            <AccountIcon
              sx={{
                color: '#000000',
                fontSize: {
                  xs: '20.572px',
                  md: 24,
                },
              }}
            />
          </IconButton>
        </Box>
      )}
    </>
  )
}
