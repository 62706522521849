/*
 * @Author: Walker Qin
 * @Description:
 * @Date: 2022-09-14 14:26:35
 * @LastEditTime: 2022-12-13 17:51:30
 */
// import CartIcon from './CartIcon'
import CartIcon from '@icons/Cart'
import IconButton from '@mui/material/IconButton'
import Link from 'next/link'
import { memo } from 'react'

export default memo(function AppHeaderCartButton() {
  // const router = useRouter()
  // const { user, token, cart } = useCart()

  // const cartCount = useMemo(() => {
  //   if (!cart || cart?.lineItems.edges.length === 0) return ''
  //   return cart.lineItems.edges.reduce(
  //     (prev, cur) => (prev += cur.node.quantity),
  //     0
  //   )
  // }, [cart])

  // const handleCartButtonClick = useCallback(() => {
  //   if (token && !user?.verified)
  //     return router.push('/account/has-not-been-activated')

  //   // 开发模式下直接跳转到online store购物车页面
  //   if (process.env.NODE_ENV === 'development')
  //     return router.push(
  //       `https://${process.env.NEXT_PUBLIC_SHOPIFY_DOMAIN}/cart`
  //     )
  //   router.push('/cart')
  // }, [router, token, user?.verified])
  return (
    <IconButton
      disableRipple
      aria-label="Cart Button"
      component={Link}
      href={`https://${process.env.NEXT_PUBLIC_SHOPIFY_PRIMARY_DOMAIN}/cart`}
    >
      <CartIcon
        sx={{
          color: '#000000',
          fontSize: {
            xs: 23,
            md: 24,
          },
        }}
      />
      {/* <Badge
        color="primary"
        badgeContent={cartCount > 99 ? '99+' : cartCount}
        invisible={!cartCount}
        sx={{
          '& .MuiBadge-badge': {
            minWidth: cartCount > 10 ? (cartCount > 99 ? 30 : 25) : 16,
            height: 16,
            top: 4,
            right: cartCount > 10 ? (cartCount > 99 ? 0 : 3) : 4,
            p: '0 4px',
            marginRight: cartCount > 99 ? '-3px' : 0,
            fontSize: 12,
          },
        }}
      >
        <CartIcon
          sx={{
            color: '#000000',
            fontSize: {
              xs: 23,
              md: 24,
            },
          }}
        />
      </Badge> */}
    </IconButton>
  )
})
