import { getAppInfo } from '@hsfe/dreojsapi'
import { configureScope, setUser } from '@sentry/nextjs'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import React, { ReactNode, useCallback, useEffect } from 'react'
import { useCookie, useLocalStorage } from 'react-use'
import { getOauthToken, getUserInfo } from 'src/api/account'
import { useUserToken } from 'src/hooks/account'
import * as auth from 'src/providers/auth-provider'
import { isDreoApp, uuid } from 'src/utils/'
import { HttpData } from 'src/utils/helpers/http'
import useSwr, { SWRResponse } from 'swr'
import {
  LoginFormData,
  LoginResponse,
  RegisterFormData,
  RegisterResponse,
  User,
} from 'types/account'

const AuthContext = React.createContext<
  | {
      user: User | undefined
      token: string
      updateUserToken: (token: string, expires: number) => void
      sendEmailVerifyCode: (data: { retry?: boolean }) => Promise<HttpData>
      loading: boolean
      mutate: SWRResponse<User | undefined, any>['mutate']
      register: (form: RegisterFormData) => Promise<HttpData<RegisterResponse>>
      login: (form: LoginFormData) => Promise<HttpData<LoginResponse>>
      handleSaveToken: (token: string, expires: number) => void
      logout: () => Promise<HttpData>
    }
  | undefined
>(undefined)

AuthContext.displayName = 'AuthContext'

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [oauthToken] = useCookie(auth.oauthCookieKey)

  const router = useRouter()
  const [userToken, setUserToken] = useUserToken()

  const [himeiId, setHimeiId] = useLocalStorage('__himei__', '')

  // 如果有app传过来的token 则直接更改为app的token
  useEffect(() => {
    if (isDreoApp()) {
      getAppInfo({
        success: (data) => {
          // user 存入cookie
          if (data.appToken) {
            setUserToken(data.appToken)
          }
        },
      })
    }
  }, [setUserToken])

  const {
    data: user,
    mutate,
    isValidating,
  } = useSwr(
    {
      url: '/user/info',
      userToken: (userToken as string) ?? window.h5Token,
      oauthToken: oauthToken as string,
    },
    async ({ userToken, oauthToken }) => {
      // 有用户token则请求用户信息
      if (userToken) {
        // 获取用户信息
        const { data: user } = await getUserInfo()

        // 生产环境cookie设置顶级域
        Cookies.set('user_id', `${user?.id}`, {
          expires: 3600,
          ...(!['dev', 'fat', 'uat'].includes(
            `${process.env.NEXT_PUBLIC_WEB_APP_ENV}`
          ) && { domain: 'dreo.com' }),
        })

        Cookies.set('nickname', `${user?.nickname}`, { expires: 3600 })

        // 添加sentry用户信息
        setUser({
          // email: `${user?.email}`,
          id: `${user?.id}`,
          username: `${user?.nickname}`,
        })
        return user
      }
      if (!oauthToken) {
        // 没有用户token则请求oauth token
        const { data } = await getOauthToken()

        // 保存oauth token
        auth.handleSaveOauthToken(
          data?.access_token as string,
          (data?.expires_in ?? 0) / 60 / 60 / 24
        )
      }
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

  // 页面跳转 如没有客户端token则重新请求
  const handleRouterStart = useCallback(async () => {
    if (!userToken && !oauthToken) {
      const { data } = await getOauthToken()
      auth.handleSaveOauthToken(
        data?.access_token as string,
        (data?.expires_in ?? 0) / 60 / 60 / 24
      )
    }
  }, [userToken, oauthToken])

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouterStart)
    return () => router.events.off('routeChangeStart', handleRouterStart)
  }, [handleRouterStart, router.events])

  const login = async (form: LoginFormData) => {
    let id = himeiId
    if (!id) {
      id = uuid().replace(/-/g, '')
      setHimeiId(id)
    }
    return auth
      .login({ ...form, himei: id })
      .then((data) => {
        // setOauthToken('')
        localStorage.setItem('refresh_token', data.data?.refresh_token ?? '')
        return data
      })
      .catch((err) => {
        return err
      })
  }

  const register = (form: RegisterFormData) => auth.register(form)

  const updateUserToken = useCallback(
    (token: string, expires: number) => {
      setUserToken(token, {
        expires,
      })
    },
    [setUserToken]
  )

  const logout = async () => {
    const res = await auth.logout()
    setUserToken('')
    Cookies.remove('user_id', {
      ...(!['dev', 'fat', 'uat'].includes(
        `${process.env.NEXT_PUBLIC_WEB_APP_ENV}`
      ) && { domain: 'dreo.com' }),
    })
    Cookies.remove('nickname')
    mutate(undefined, false)

    // 清除sentry用户信息
    configureScope((scope) => scope.setUser(null))
    return res
  }
  // useMount(() => {
  //   bootstrap()
  // })

  return (
    <AuthContext.Provider
      value={{
        user,
        token: userToken ?? window.h5Token,
        updateUserToken,
        loading: isValidating,
        sendEmailVerifyCode: auth.sendEmailVerifyCode,
        login,
        mutate,
        register,
        handleSaveToken: auth.handleSaveToken,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  const context = React.useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be use in AuthProvider')
  }

  return context
}
